import { gql, useMutation, useQuery } from "@apollo/client";
import mixpanel from "mixpanel-browser";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";

import { EditableNote } from "./edit/EditableNote";
import { recordedNoteSteps } from "../../assets/data/JoyrideTourSteps";
import { NOTE_FIELDS } from "../../graphql/fragments/note";
import withTemplatesMessageModal from "../../hocs/withTemplatesMessageModal.js";
import { useNoteUsage } from "../../hooks";
import { alert } from "../common/Alert";
import { Banner } from "../common/Banner";
import JoyrideTour from "../common/modals/JoyrideTour";
import PageTitle from "../layout/PageTitle.js";
import { RETRY_FAILED_AUTO_SOAP } from "../notebook/note/NoteStatus";

export const GET_NOTE = gql`
  ${NOTE_FIELDS}
  query getNote($uuid: String) {
    note(uuid: $uuid) {
      user {
        uuid
        shouldUseAutoSoaps
        firstName
        lastName
        account {
          headerInfoPdf
          logoFileLink
        }
      }
      patient {
        uuid
        name
        lastNameOfOwner
        sex
        species
      }
      ...NoteFields
      attachments {
        uuid
        fileName
        fileLink
        contentType
        createdAt
      }
      keyHistory
      keyMedications
      vaccinationStatus
      audioStorageLink
      isAutoSoap
      isIncomplete
      isUnacceptable
      dataExtraction
      thumbsUp
      feedback
      timeToProcess
    }
  }
`;

const CHECKOUT_NOTE = gql`
  mutation checkoutNote($uuid: String) {
    checkoutNote(noteUuid: $uuid) {
      ok
      user {
        uuid
        firstName
        lastName
      }
    }
  }
`;

const CHECKIN_NOTE = gql`
  mutation checkinNote($uuid: String) {
    checkinNote(noteUuid: $uuid) {
      ok
    }
  }
`;

function EditNote() {
  const [runJoyrideTour, setRunJoyrideTour] = useState(false);
  const [checkoutNote, { data: checkoutData }] =
    useMutation(CHECKOUT_NOTE);

  const [checkinNote] = useMutation(CHECKIN_NOTE);

  useEffect(() => {
    checkoutNote({
      variables: {
        uuid: noteUUID,
      },
    });
    return () => {
      checkinNote({
        variables: {
          uuid: noteUUID,
        },
      });
    };
  }, []);

  const [retryFailedAutoSoap] = useMutation(RETRY_FAILED_AUTO_SOAP);

  const navigate = useNavigate();

  const { hasReachedAutoSoapLimit } = useNoteUsage();

  const { noteUUID } = useParams();
  const { data, loading } = useQuery(GET_NOTE, {
    variables: { uuid: noteUUID },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    mixpanel.track("Started Editing Note Page", {
      noteUUID: noteUUID,
    });
    return () => {
      mixpanel.track("Exited Editing Note Page", {
        noteUUID: noteUUID,
      });
    };
  }, []);

  if (!data || loading) {
    return null;
  }

  function renderBanner() {
    if (data?.note?.isAutoSoap && data?.note?.isIncomplete) {
      return (
        <Banner bgStyle="bg-yellow-400">
          We've detected that the audio for this note might be
          incomplete. Please check the audio recording. <br /> If you
          think this is a mistake,please send the note to our support
          team using the "Something Wrong?" button.
        </Banner>
      );
    } else if (data?.note?.isUnacceptable) {
      return (
        <Banner bgStyle="bg-red-400">
          We’ve detected that this audio cannot be made into an note.{" "}
          Please check the audio recording. <br /> If you think this
          is a mistake, please send the note to our support team using
          the "Something Wrong?" button.
        </Banner>
      );
    } else {
      return null;
    }
  }

  return (
    <div>
      <PageTitle
        title="Edit Note"
        onClick={() => {
          setRunJoyrideTour(true);
        }}
        showHelpButton
      />

      <JoyrideTour
        steps={recordedNoteSteps}
        runJoyrideTour={runJoyrideTour}
        setRunJoyrideTour={setRunJoyrideTour}
      />

      {renderBanner()}

      {!data?.note?.isTagged &&
      !data?.note?.isApproved &&
      !data?.note?.isAutoSoap &&
      data?.note?.audioStorageLink &&
      !hasReachedAutoSoapLimit &&
      data?.note?.user?.shouldUseAutoSoaps ? (
        <div>
          <Banner bgStyle="bg-green-400">
            You can turn this recording into an Auto-Note!{" "}
            <button
              className="underline"
              onClick={() => {
                retryFailedAutoSoap({
                  variables: {
                    noteUuid: noteUUID,
                  },
                })
                  .then(() => {
                    alert(
                      "warning",
                      "Submitting as Automatic Note...",
                    );
                    navigate("/dashboard");
                  })
                  .catch((error) => {
                    alert("error", error.message);
                  });
              }}
            >
              Click here to turn this recording into a note
              automatically.
            </button>
          </Banner>
        </div>
      ) : null}
      <EditableNote
        note={data?.note}
        status={checkoutData?.checkoutNote}
      />
    </div>
  );
}

export default withTemplatesMessageModal(EditNote);
